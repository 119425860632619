import { graphql } from 'gatsby'
import React, { useState } from 'react'
import Hero from 'src/components/Hero'
import LoginSection from 'src/components/LoginSection'
import { renderElements } from 'src/utils/elements'

const PageTemplate = ({ data }) => {
  const page = data.wpPage
  const elements = page.plakativPageElements.pageElements
  const hero = page.plakativHero
  const options = page.plakativOptions

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  return !!options &&
    !!options.optionsIsProtected &&
    !!options.optionsPassword &&
    !isLoggedIn ? (
    <LoginSection
      password={options.optionsPassword}
      setIsLoggedIn={setIsLoggedIn}
    />
  ) : (
    <>
      <Hero
        heading={hero.heroHeading}
        fullHeight={hero.heroFullHeight}
        image={hero.heroImage}
        video={hero.heroVideo}
        button={hero.heroButton}
        hasBillboard={hero.hasBillboard}
      />
      {elements && renderElements(elements)}
    </>
  )
}

export const pageQuery = graphql`
  query myPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      plakativHero {
        heroHeading
        hasBillboard
        heroFullHeight
        heroButton {
          target
          title
          url
        }
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        heroVideo {
          altText 
          title
          mediaItemUrl
        }
      }
      plakativOptions {
        optionsIsProtected
        optionsPassword
      }
      plakativPageElements {
        ...PageElementsFragment
      }
    }
  }
`
export default PageTemplate
